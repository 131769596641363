<template>
    <el-dialog
        class="editDialog"
        :append-to-body="true"
        width="600px"
        :visible.sync="showEditDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @close="$emit('update:isShow', false)"
        :show-close="true"

    >

        <div slot="title" class="title">角色授权</div>

        <div class="content" v-loading="loading">
            <el-alert title="勾选菜单需勾选到最末级" type="warning"></el-alert>
            <el-tree
                ref="tree"
                show-checkbox
                node-key="id"
                :data="allTreeData"
                :default-checked-keys="selectedTreeData"
                :props="{children: 'children',label: 'name'}"
                :default-expand-all="true"
                :check-strictly="true">
                    <span slot-scope="{ node, data }">
                      {{ node.label }} <span v-if="data.nickname" style="opacity:.7;">({{ data.nickname }})</span>
                    </span>
            </el-tree>
        </div>

        <div slot="footer" v-show="!loading">
            <el-button type="primary" :loading="submitLoading" @click="submit">保存</el-button>
            <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        </div>

    </el-dialog>

</template>
<script>

import {initTreeData} from '@/util/common'
export default {
    components: {},
    props: {
        isShow: {
            type: Boolean,
            default: false
        },
        options: {
            type: Object,
            default:function(){
                return {}
            }
        },
        refresh: {
            type: Function,
            default: () => {}
        },
    },
    data() {
        return {
            showEditDialog: false,
            loading: true,
            submitLoading: false,

            allTreeData: [],
            selectedTreeData: [],
        };
    },
    watch: {
        isShow: {
            immediate: true,//初始化立即执行
            handler: function (newVal) {
                this.showEditDialog = newVal;
            }
        },
        options:{
            immediate:true,//初始化立即执行
            deep: true,//对象深度监测
            handler: function (newVal,oldVal){
                if(newVal.id){
                    this.init();
                }
            }
        }
    },
    created() {

    },
    methods: {
        init(){
            this.loading = true;
            this.selectedTreeData = [];
            this.$api['admin/menu'].queryMenuByRoleId(this.options.id).then(res => {
                this.allTreeData = initTreeData(res.data.all);
                if (res.data.list&&res.data.list.length > 0) {
                    for (var i in res.data.list) {
                        this.selectedTreeData.push(res.data.list[i].id);
                    }
                };
                this.loading = false;

            }).catch(err => {
                this.loading = false;
                console.log(err);
            })
        },
        submit(){
            this.submitLoading = true;

            var params = {
                menuId: [],
                roleId: this.options.id,
            };
            this.$refs.tree.getCheckedNodes().forEach(item=>{
                params.menuId.push(item.id);
            });
            this.$api['admin/role'].saveMenuRole(params).then( res => {
                this.submitLoading = false;
                this.refresh();
                this.$message.success("操作成功！");

            }).catch(err=>{
                this.submitLoading = false;
                console.log(err);
            })
        },

    },
};
</script>
